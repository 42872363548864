<template>
  <div>
    <div class="flex flex-col space-y-5 justify-center items-center text-center">
      <div class="relative flex space-x-5 bg-primary rounded-10 px-10 py-2">
          <div class="overflow-hidden w-24 h-16 rounded-full">
              <img :src="detailTopup.dataInquiry.foto" class="object-cover bg-center w-full h-full" :alt="detailTopup.dataInquiry.nama">
          </div>
          <div class="flex flex-col justify-between items-start h-full w-full">
              <div class="text-white text-xs font-normal">
                Account name
              </div>
              <div class="text-white text-base font-semibold">
                {{ detailTopup.dataInquiry.beneficiary_account_name }}
              </div>
              <div class="text-white text-xs font-normal">
                Phone number
              </div>
              <div class="text-white text-base font-semibold">
                {{ detailTopup.phone }}
              </div>
          </div>
          <font-awesome-icon icon="fa-solid fa-floppy-disk" class="absolute right-3 top-3 cursor-pointer text-white text-lg" @click="saveData"/>
      </div>
      <div class="text-black">
        <div class="text-base font-semibold">Amount</div>
        <div class="text-3xl font-black">{{$store.state.currency.kode_domestik}}{{ detailTopup.amount }}</div>
        <div class="text-lg font-black" v-show="$store.state.countryCode != 'ID'">Equal To</div>
        <div class="text-3xl font-black" v-show="$store.state.countryCode != 'ID'">Rp{{ nominalReal }}</div>
      </div>
      <div class="flex flex-col w-72">
        <div class="rounded-t-10 bg-primary text-white text-xs font-semibold py-2 px-5 whitespace-nowrap">
          Transfer Information
        </div>
        <div class="rounded-b-10 border border-primary bg-white text-51 text-xs font-semibold py-2 px-5 text-left">
          {{ detailTopup.note }}
        </div>
      </div>
    </div>
    <div class="flex space-x-5 justify-end w-full mt-20">
      <div
        @click="cancel"
        class="bg-f5 rounded-10 text-base text-black font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        Cancel
      </div>
      <div 
        @click="payment"
        class="flex space-x-3 items-center bg-primary rounded-10 text-base text-white font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        <img :src="icTf" alt="Transfer">
        <div class="">Transfer</div>
      </div>
    </div>
    <modal-pin ref="modalPin"></modal-pin>
  </div>
</template>

<script>
import { NumberFormat } from "@/utils";
import Vue from 'vue';

export default {
  name: "ToTripwePayFinal",
  components: {
      ModalPin: () => import('@/components/layout/ModalPin.vue'),
  },
  data: () => ({
    icSave: require("@/assets/icons/ic-save.svg"),
    icTf: require("@/assets/icons/ic-tf-white.svg"),
  }),
  methods: {
    numberFormat: num => NumberFormat(num),
    cancel() {
      return this.$router.go(-1); 
    },
    async payment(){
        const pin = await this.$refs.modalPin.open({
          button: 'OK'
        });
        
        if(pin == null){
            return;
        }

        try {
          this.$store.commit('setOverlayLoading', true);
          const res = await this.$http.post(`${this.$apiTripweWeb}/cashless-to-cashless/transfer`, {
            "reference_no":this.detailTopup.dataInquiry.reference_no,
            "pin":pin,
          });
          if(res.data.status != '200'){
            throw res.data.message;
          }
          if(res.data.status == '200'){
            var resSaldo = await this.$http.get(`${this.$apiTripweWeb}/tripwepay/balance`);
            this.$store.commit('setSaldo', resSaldo.data.response);
            this.cancel();
            if(res.data.data.status_code == '00'){
              this.$toasted.global.success("Transfer Success");
            }else{
              this.$toasted.global.success(res.data.data.status_desc);
            }
          }
        } catch (error) {
          this.$toasted.global.error(error);
        } finally {
          this.$store.commit('setOverlayLoading', false);
        }
    },
    async saveData(){
      this.$store.commit('setOverlayLoading', true);
      
      var fData = new FormData();
      fData.append("txtVa", this.detailTopup.dataInquiry.beneficiary_account);
      fData.append("txtNama", this.detailTopup.dataInquiry.beneficiary_account_name);
      fData.append("txtTelepon", this.detailTopup.phone);
      fData.append("txtId", this.$store.state.user?.id_user);
      fData.append("mobile", "android");

      const res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/insert_daftar_transfer_pengguna_v16.php`,fData);
      if(res.data == 'success'){
        this.$toasted.global.success('Data transaction has been saved');
      }
      this.$store.commit('setOverlayLoading', false);
    }
  },
  created() {
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    detailTopup() {
      return this.$route.params.data;
    },
    nominalReal() {
      var val = Vue.filter('exchangeCurrency')(this.detailTopup.amount);
      return val;
    }
  },
};
</script>